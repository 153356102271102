import React, { Suspense, lazy, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState } from "react";
import LoadingIcon from "./assets/gif/loader.gif";
import { useSelector } from "react-redux";
// import Profile from "./layout/AssessorPage/Profile/profile";
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import AssessorSettings from "./layout/AssessorPage/Settings/Settings/assessorSettings";
import Calendar from "./layout/AssessorPage/Calendar/calendar";
import PartnerShips from "./layout/AssessorPage/PartnerShips/partnerShips";
import AxiosInterceptorSetup from "./utils/axiosInterceptorSetup";
import { ServerError } from "./shared/Popup/SuccessError";
import moment from "moment";
// import withMetaTags from "./utils/withMetaTags.js"

// import Dashboard from "./layout/Dashboard/dashboard";
// import Register from "./layout/Register/register";
// import Login from "./layout/Login/login";
// import CompanyProfile from "./layout/CampanyProfile/campanyProfile"
// import JobBoard from "./layout/JobBoard/jobboard"
// import MyJobs from "./layout/JobBoard/myjobs"
// import QuestionBank from "./layout/QuestionBank/questionbank"
// import CreateQuestionBank from "./layout/QuestionBank/createquestionbank"
// import PostJob from "./layout/PostJob/postjob";
// import CandidateProfile from "./layout/Candidate/CandidateProfile/candidateProfile";
// import PrivateComponent from "./PrivateComponent";
// import Assessment_dashboard from "./layout/Candidate/Assessment/assessmentDashboard";
// import TestQuestion from "./layout/Candidate/Assessment/testQuestions";
// import Questionnaire from "./layout/JobBoard/questionnaire";
// import Activity from "./layout/Candidate/CandidateProfile/myActivity";
// import FindJob from "./layout/Candidate/CandidateProfile/findJobs";
// import Team from "./layout/Team/team";
// import ScheduleCandidate from "./layout/InterviewSchedule/ScheduleCandidate";

const Dashboard = lazy(() => import("./layout/Dashboard/dashboard"));
const Register = lazy(() => import("./layout/Register/EmpRegister/register"));
const FreeAssRegister = lazy(() => import("./layout/Register/FreeAssRegister/freeAssRegister"));
const Login = lazy(() => import("./layout/Login/EmpAssLogin/login"));
const FreelanceAssLogin = lazy(() => import("./layout/Login/FreeAssLogin/freeAssLogin"));
const ResetPassword = lazy(() => import("./layout/Login/ResetPassword/resetPassword"));
const CompanyProfile = lazy(() =>
  import("./layout/CampanyProfile/campanyProfile")
);
const JobBoard = lazy(() => import("./layout/JobBoard/jobboard"));
const MyJobs = lazy(() => import("./layout/JobBoard/myjobs"));
const CreateQuestionBank = lazy(() =>
  import("./layout/QuestionBank/createquestionbank")
);
const PostJob = lazy(() => import("./layout/PostJob/postjob"));
const PrivateComponent = lazy(() => import("./PrivateComponent"));
const QuestionBank = lazy(() => import("./layout/QuestionBank/questionbank"));
// const Questionnaire = lazy(() => import("./layout/JobBoard/questionnaire"));
const Team = lazy(() => import("./layout/Team/team"));
const EmpCandidates = lazy(() => import("./layout/EmpCandidates/EmpCandidates"));
const EmpCandidatesViewProfile = lazy(() => import("./shared/Popup/CandidateProfile/CandidateProfile"));
const Settings = lazy(() => import("./layout/Settings/Settings"));

const Assessor = lazy(() => import('./layout/Assessor/Assessor'));
const InterviewSchedule = lazy(() => import('./layout/AssessorPage/InterviewSchedule/InterviewSchedule'));
const ScheduleCandidate = lazy(() => import('./layout/AssessorPage/InterviewSchedule/ScheduleCandidate'));
const NotFoundPage = lazy(() => import('./shared/PageNotFound/NotFoundPage'));
const AssessorDashboard = lazy(() => import('./layout/AssessorPage/AssessorDashboard/AssessorDashboard'));
const Evaluations = lazy(() => import('./layout/AssessorPage/Evaluations/Evaluations'));
const Interviews = lazy(() => import('./layout/Interviews/Interviews'));
const JobInfo = lazy(() => import('./layout/ShowDocs/jobInfo'));
const TestResults = lazy(() => import('./layout/ShowDocs/testResults'));
const ConfigQuestionnaire = lazy(() => import('./layout/JobBoard/Questionnaire/ConfigQuestionnaire.js'));
const InviteFreelance = lazy(() => import('./layout/Assessor/inviteFreelance/InviteFreelance'));

function App() {
  const [isLoading, setIsLoading] = useState(false);

  const { recruiter } = useSelector((state) => state.home)
  const { assessor } = useSelector((state) => state.home)
  let getSearchUrl = window.location;
  let queryParams = new URLSearchParams(getSearchUrl.search);
  let getId = queryParams.get("id");
  let getResetPassEmail = queryParams.get("email");

  // axios.interceptors.request.use(
  //   (config) => {
  //     config.headers.common["Accept"] = "application/json";
  //     // console.log("request sent");
  //     setIsLoading(true);
  //     return config;
  //   },
  //   (error) => {
  //     return Promise.reject(error);
  //   }
  // );

  // axios.interceptors.response.use(
  //   (response) => {
  //     setIsLoading(false);
  //     return response;
  //   },
  //   (error) => {
  //     // handle error
  //     return Promise.reject(error);
  //   }
  // );

  // const EmpCandidatesViewProfileWithMetaTags = withMetaTags(EmpCandidatesViewProfile);

  useEffect(() => {
    const getTimeZone = sessionStorage.getItem("timeZone");
    let currentdateAndTime = moment().format("YYYY-MM-DD HH:mm:ss");

    // try {
    //   fetch("https://worldtimeapi.org/api/timezone/" + getTimeZone)
    //     .then(response => response.json())
    //     .then(data => {
    //       console.log(data.datetime)
    //       if (data.datetime) {
    //         currentdateAndTime = moment(data.datetime).format("YYYY-MM-DD HH:mm:ss");
    //       }
    //       sessionStorage.setItem("currentTime", currentdateAndTime);
    //     }).catch(err => {
    //       if (err) {
    //         sessionStorage.setItem("currentTime", currentdateAndTime);
    //       }
    //     });
    // } catch (error) {
    //   if (error) {
    //     sessionStorage.setItem("currentTime", currentdateAndTime);
    //   }
    // }

    sessionStorage.setItem("currentTime", moment().format("YYYY-MM-DD HH:mm:ss"));
  }, [])

  return (
    <>
      {isLoading && (
        <div className="loader-wrapper">
          <div className="spin">
            <img src={LoadingIcon} />
          </div>
        </div>
      )}
      <BrowserRouter>
        {/* <Suspense fallback={<div> Please wait......</div>}> */}
        <Suspense fallback={<div className="loader-wrapper"><div className="spin"> <img src={LoadingIcon} /> </div></div>} >
          {/* <AxiosInterceptorSetup /> */}
          <Routes>
            {/* {!getId ?  */}
            <Route path="/" element={<Login />} />
            {/* : */}
            <Route path="/id/:id" element={<EmpCandidatesViewProfile getId={getId} />} />
            <Route path="employer/ResetPassword/email/:email" element={<ResetPassword getResetPassEmail={getResetPassEmail} />} />
            {/* } */}
            <Route path="/Register" element={<Register />} />
            <Route path="/Assessor-Register" element={<FreeAssRegister />} />
            <Route path="/confirmDetails" element={<LinkedInCallback />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Assessor-Login" element={<FreelanceAssLogin />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/server-error" element={<ServerError />} />
            <Route path="/JobInfo" element={<JobInfo />} />
            <Route path="/TestResults" element={<TestResults />} />
            <Route element={<PrivateComponent />}>
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/CompanyProfile" element={<CompanyProfile />} />
              <Route path="/JobBoard" element={<JobBoard />} />
              <Route path="/MyJobs" element={<MyJobs />} />
              <Route path="/Questionnaire" element={<ConfigQuestionnaire />} />
              <Route path="/QuestionBank" element={<QuestionBank />} />
              <Route path="/CreateQuestionBank" element={<CreateQuestionBank />} />
              <Route path="/CompanyProfile" element={<CompanyProfile />} />
              <Route path="/Team" element={<Team />} />
              <Route path="/postjob" element={<PostJob />} />
              <Route path="/Candidates" element={<EmpCandidates />} />
              <Route path="/Settings" element={<Settings />} />
              <Route path="/Assessor" element={<Assessor />} />
              <Route path="/InviteFreelance" element={<InviteFreelance />} />
              <Route path="/Interviews" element={<Interviews />} />
              <Route path="/InterviewSchedule" element={<InterviewSchedule />} />
              <Route path="/AssessorDashboard" element={<AssessorDashboard />} />
              <Route path="/Evaluations" element={<Evaluations />} />
              <Route path="/ScheduleCandidate" element={<ScheduleCandidate />} />
              <Route path="/Candidates/profile" element={<EmpCandidatesViewProfile />} />
              <Route path="/Interviews/profile" element={<EmpCandidatesViewProfile />} />
              {/* ----------------- Assessor --------------- */}
              <Route path="/assessorSettings" element={<AssessorSettings />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/partner-ships" element={<PartnerShips />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
