const BASE_URL = "https://restapi.theshortlistd.com"
export const BaseUrl = "https://restapi.theshortlistd.com"
export const empUrl = "https://employer.theshortlistd.com"
export const candidateUrl = "https://www.theshortlistd.com"


export const APIs = {
    "passThroughAPI": `${BASE_URL}/JobzTop/PassThrough?request=`,

    "jobAPI": `${BASE_URL}/JobzTop/Job?request=`,

    "assessorAPI": `${BASE_URL}/JobzTop/Assessor?request=`,

    "assessorAPI2": `${BASE_URL}/JobzTop/Assessor`,

    "employerProfileApi": `${BASE_URL}/JobzTop/EmployerProfile?request=`,

    "profileApi": `${BASE_URL}/JobzTop/Profile?request=`,

    "employerRegistrationApi": `${BASE_URL}/JobzTop/EmployerRegistration?request=`,

    "registrationApi": `${BASE_URL}/JobzTop/Registration?request=`,

    "skillApi": `${BASE_URL}/JobzTop/Skill?request=`,

    "qualificationApi": `${BASE_URL}/JobzTop/Qualification?request=`,

    "LinksApi": `${BASE_URL}/JobzTop/Links?request=`,

    "questionBankApi": `${BASE_URL}/JobzTop/QuestionBank?request=`,

    "downloadresume": `${BASE_URL}/JobzTop/PassThroughV2?code=yyy&id=`,

    // "downloadresume":       `${BASE_URL}/GroupzFS/download?id=`,

    "downloadSampleCsv": 1244, // 63 for production and 1244 for QA

    "shareJobLink": `${candidateUrl}/?id=`,

    "downloadImage": `${BASE_URL}/JobzTop/PassThroughV2?code=aaa&type=thumbnl&id=`,

    "downloadVideo": `${BASE_URL}/JobzTop/PassThroughV2?code=ccc&id=`,

    "showPhoto": `${BASE_URL}/GroupzFS`,

    "searchJobApi": `${BASE_URL}/JobzTop/SearchJob?request=`,

    "uploadHistoryApi": `${BASE_URL}/JobzTop/UploadHistory?request=`,

    "downloadsFilesApi": `${BASE_URL}/JobzTop/DownloadsFiles?request=`,

    "upldApi": `${BASE_URL}/JobzTop/upld/fl`,

    "locationApi": `${BASE_URL}/JobzTop/Location?request=`,

    "termsAndConditionUrl": `${candidateUrl}/terms-and-conditions`,

    "redirect_url": empUrl,

    "CSV_bulkUpload": `${empUrl}/SampleCSV-BulkUpload.csv`,

    "google_map_api" : "AIzaSyBooOVDhsVGeAWPPjEjI_RBUIVpFK-e3ow"
}

export const globalVar = {
    "maxChargesForAssessor": 600,
    "maxCharCount": 1500,
    "maxCharCountForJobDesc": 2500,
}

export const credentials = {
    "scope": "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.app.created https://www.googleapis.com/auth/calendar.calendarlist https://www.googleapis.com/auth/calendar.calendarlist.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.owned https://www.googleapis.com/auth/calendar.events.owned.readonly https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    "clientId": "773281784707-ne42ltlk0kpk1eaa4hgs1sref64ktk3n.apps.googleusercontent.com",

    "linkedInScope": "openid profile email r_basicprofile",
    "linkedInClientId": "86laduhx9sbmip",
    "linkedInState": "J6OBZTOPS1U2T3h",
    "linkedInClientSecret": "9QIyCV4b9ZT4P9ZD",
    "linkedInGrantType": "authorization_code",
}

export const VideoCall_BASE_URL = "https://groupzconf.theshortlistd.com"
// export const BASE_URL_RESUME  = "http://52.221.14.247:8585"